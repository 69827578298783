import React from 'react'
import styled, { css } from 'styled-components'

const width = '187'
const height = '120'
const viewBox = '0 0 187 120'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({noStyles}) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='none'
      fillRule='evenodd'
      key='key-0'
    >
      <path
        fill='#EDEDED'
        d='M0 0h187v120H0z'
      />
      <g
        fill='#212121'
      >
        <path
          d='M137.835 55.554c-2.79 0-6.712 1.482-6.712 8.988 0 4.112 1.796 8.462 6.475 8.462 3.12 0 5.293-2.106 5.813-5.643h5.388c-.993 6.406-4.915 9.943-11.2 9.943-7.657 0-11.863-5.496-11.863-12.762 0-7.457 4.017-13.29 12.052-13.29 5.67 0 10.493 2.869 11.01 8.94h-5.378c-.434-3.012-2.608-4.638-5.585-4.638zm-34.598 8.971c0 4.886 2.623 8.554 7.174 8.554 5.146 0 7.075-4.325 7.075-8.554-.004-4.416-2.428-8.6-7.077-8.6-4.746 0-7.172 3.431-7.172 8.6zm.198-21.428v12.405h.1c1.533-2.349 4.749-3.806 7.618-3.806 8.064 0 11.973 5.829 11.973 12.925 0 6.53-3.463 12.686-11.033 12.686-3.463 0-7.174-.797-8.857-3.994h-.097v3.338h-5.344V43.098l5.64-.001zM78.647 77.288H38.101l18.934-34.189h40.546z'
        />
      </g>
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ButtonContactBc'
})
