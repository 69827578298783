import React from 'react'
import styled, { css } from 'styled-components'

const width = '187'
const height = '120'
const viewBox = '0 0 187 120'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({noStyles}) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='none'
      key='key-0'
    >
      <path
        fill='#EDEDED'
        d='M0 0h187v120H0z'
      />
      <path
        fill='#212121'
        d='M103.875 40h-22.75A8.126 8.126 0 0 0 73 48.125v22.75A8.126 8.126 0 0 0 81.125 79h22.75A8.125 8.125 0 0 0 112 70.875v-22.75A8.125 8.125 0 0 0 103.875 40zm-1.796 30.45c-.851 1.124-3.72 2.05-6.32 2.05-6.465 0-9.759-2.382-9.759-7.431v-6.67h-3.141l-.109-.108v-5.135l.073-.104.29-.096c2.595-.868 4.263-2.327 4.632-5.336.09-.727.7-1.12 1.23-1.12h7.747l.107.109v5.218l.107.109h4.12l.11.108v6.247l-.11.109H96.92l-.109.109V64.7c.003.167.01.673.177.673h3.546l.104.074 1.612 4.78-.17.224zm-2.127-3.7l1.08 3.195-.027.114c-.812.78-2.767 1.36-4.364 1.388l-.18.002c-5.253 0-6.156-4.01-6.156-6.38v-7.625l-.108-.109h-3.012l-.11-.108-.025-3.317.068-.1c2.714-1.057 4.232-2.812 4.63-6.06.022-.18.17-.186.172-.186h3.734l.109.11v5.217l.11.109h4.12l.107.109v4.118l-.107.108h-4.139l-.104.11v7.263c.026 1.558.775 2.348 2.238 2.348.588 0 1.209-.136 1.825-.372l.14.065z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ButtonContactTr'
})
