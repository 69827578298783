import { Box, Text } from 'rebass'
import styled from 'styled-components'
import { media, borderHighlightColour } from '../../theme/'

export const Content = styled(Box)`
  color: white;
  position: relative;
  z-index: 50;
`
export const RegText = styled(Text)`
  opacity: 0.5;
`
export const ContactList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  & > li {
    display: block;
    font-weight: 700;
    margin-bottom: 0.85em;
  }
  svg {
    margin-right: 15px;
    transform: translateY(7px);
  }
`

export const Form = styled(Box)`
  input {
    font-size: 1em;
    border: 0;
    width: 100%;
    ${media.LargeScreenUp`
      width: 55%;
    `}
    display: block;
    padding: 0.5em;
    margin-top: 0.2em;
    margin-bottom: 0.5em;
    background-color: white;
    opacity: 0.8;
    border-bottom: 2px grey solid;
  }
  input:focus {
    outline: 3px white solid;
    opacity: 1;
  }
  textarea {
    font-size: 1em;
    display: block;
    padding: 0.5em;
    width: 100%;
    height: 130px;
    ${media.LargeScreenUp`
      width: 55%;
      height: 90px;
    `}
    background-color: white;
    border-bottom: 2px grey solid;
    opacity: 0.8;
  }
  textarea:focus {
    outline: 3px white solid;
    opacity: 1;
  }
  textarea:invalid  {
    border-bottom: 2px ${borderHighlightColour} solid;
    opacity: 1;
  }
  button {
    background-color: ${borderHighlightColour};
    color: white;
    border: 0;
    width: 100%;
    padding: 1em;
    ${media.LargeScreenUp`
      width: 55%;
      padding: 0.5em;
    `}
    cursor: pointer;
    
    font-size: 1em;
  }
`
