import React from 'react'
import styled, { css } from 'styled-components'

const width = '187'
const height = '120'
const viewBox = '0 0 187 120'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({noStyles}) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='none'
      fillRule='evenodd'
      key='key-0'
    >
      <path
        fill='#EDEDED'
        d='M0 0h187v120H0z'
      />
      <path
        fill='#212121'
        fillRule='nonzero'
        d='M113 46.99a15.236 15.236 0 0 1-4.596 1.34c1.653-1.052 2.922-2.72 3.519-4.708a15.46 15.46 0 0 1-5.082 2.066c-1.457-1.654-3.539-2.688-5.84-2.688-5.166 0-8.962 5.126-7.795 10.448-6.648-.354-12.544-3.742-16.49-8.89-2.097 3.824-1.088 8.828 2.474 11.362a7.608 7.608 0 0 1-3.622-1.065c-.088 3.943 2.57 7.631 6.417 8.452a7.555 7.555 0 0 1-3.614.145c1.017 3.381 3.972 5.84 7.475 5.91-3.364 2.805-7.602 4.058-11.846 3.526 3.54 2.414 7.748 3.823 12.266 3.823 14.855 0 23.248-13.345 22.741-25.314A16.904 16.904 0 0 0 113 46.99z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ButtonContactTw'
})
