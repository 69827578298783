import React from 'react'
import { graphql } from 'gatsby'
import { first } from 'lodash'
import { black } from '../theme'
import { isWindowAvailable } from '../util'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Router from '../components/Router'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Stripe from '../components/Stripe'
import PageTitle from '../components/PageTitle'

class ArtistDetailsTemplate extends React.Component {
  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const normalizedListing = {
      address: currentPage.content,
      regnumber: currentPage.acf.contact_reg,
      email: currentPage.acf.contact_email,
      telephone: currentPage.acf.contact_telephone
    }
    const bodyStyle = (isWindowAvailable) ? { style: `background-color: ${black.mid};` } : null
    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} bodyAttributes={bodyStyle} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${currentPage.title}`} bodyAttributes={bodyStyle} />
        }
        <Router
          items={first(this.props.data.allWordpressWpApiMenusMenusItems.edges).node.items}
        />
        <PageTitle title={currentPage.title} />
        <Contact info={normalizedListing} />
        <Stripe top />

        <Footer />
      </Layout>
    )
  }
}
// artist-details-page
export default ArtistDetailsTemplate

export const ArtistDetailsQuery = graphql`
  query artistDetailsPageQuery($id: String!) {
    allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        seo_page_title
        contact_reg
        contact_email
        contact_telephone
      }
    }
    
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
